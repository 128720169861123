<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">
		<img src="../../assets/index/in.jpg" alt="">
		<!-- <span>INDUSTRIES & APPLICATIONS</span> -->
	</div>
    <div class="title">
      Closeness and timely response to every customer requirements, together with continuous innovation and high quality offering, are the distinctive elements at the base of Orchid success for over Its cutting-edge solutions help to increase the efficiency and quality of processes in the Retail, Manufacturing, Transportation & Logistics and Healthcare industries, along the entire value chain.
    </div>
    <!-- 产品product -->
    <div class="product">
      <div class="product_main">
        <div
          class="product_main_box"
          v-for="item in oneTypeList"
          :key="item.id"
          @click="retail(item.id)"
        >
          <img :src="item.url" alt="" />
          <div>
            <div>{{ item.name }}</div>
            <div>{{ item.description }}</div>
          </div>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "", title: "INDUSTRIES & APPLICATIONS" },
    ];
    this.applicationOneTypeList();
  },
  data() {
    return {
      breadcrumbList: [],
      oneTypeList: [], //一级分类列表
    };
  },
  methods: {
    //获取行业与应用一级分类
    applicationOneTypeList() {
      api.applicationOneTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.oneTypeList = data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //一级标签详情的跳转
    retail(id) {
      this.$router.push({ path: "/appRetail", query: { id } });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  // background-image: url("../../assets/index/carouseli.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  // height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  // line-height: 186px;
  text-align: center;
  position: relative;
  img{
	  max-width: 100%;
  }
  span{
	  // z-index: 999;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%,-50%);
	  font-size: 3.5rem;
	  font-family: Arial;
	  font-weight: bold;
  }
}
.title {
  margin: 33px auto 37px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
}
.product {
  min-height: 350px;
  .product_title {
    text-align: center;
    height: 53px;
    padding: 30px;
    font-size: 30px;
    font-family: Arial;
    font-weight: bold;
    color: #004a9c;
  }
  .product_main {
    width: 1156px;
    margin: 0 auto;

    display: flex;

    flex-wrap: wrap;
    .product_main_box {
      position: relative;
      height: 357px;
      width: 207px;
      margin-bottom: 30px;
      cursor: pointer;

      img {
        width: 207px;
        height: 207px;
        display: block;
      }
      div {
        width: 207px;
        height: 155px;
        background: #f0f2f7;
        > div:nth-of-type(1) {
          width: 177px;
          text-align: center;
          font-size: 16px;
          font-family: Arial;
          font-weight: bold;
          color: #5a5b5e;
          line-height: 60px;
          height: 60px;
          padding: 0 15px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        > div {
          text-align: center;
          width: 177px;
          padding: 0 15px;

          height: 52px;
          font-size: 16px;
          line-height: 26px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .product_main_box:hover {
      span {
        position: absolute;
        width: 207px;
        height: 202px;
        display: block;
        background: #172f66;
        left: 0;
        top: 0;
        opacity: 0.2;
      }
      div {
        > div {
          color: #ffffff;
        }
        background: #004a9c;
      }
    }
    .product_main_box:not(:nth-of-type(5n)) {
      margin-right: 30px;
    }
  }
}
</style>